// TODO map and app store link
import React from 'react'
import './Products.css'
import {
    ATM1,
    Buy,
    Location,
    Download,
    Phone,
    Call,
    Pos,
    Ball,
    Gift
} from '../../Assets/Image'

const Products = () => {

function navigateTo(path) {
    if (path === "buy") {
        window.open(`https://crypto-atm.shop`, "_blank")
    }
    // TODO map
    if (path === "find") {
        window.location.href = "/find"
    }
    if (path === "download") {
        // TODO link to app store
    }
    if (path === "call") {
        window.open(`https://calendly.com/velorex/30min`, "_blank")
    }
}

  return (
    <div>
        <section className="product-section">
            <div className="product-container">
                {/* ATM */}
                <div className="product-card">
                    <div className="top-left">
                        <div className="top-link" onClick={() => navigateTo("buy")}>
                            <div className="top-image">
                                <img src={Buy} alt="Buy a Velorex Crypto ATM" />
                            </div>
                            <div className="top-text-container">
                                <p className='top-bold'>Buy ATM</p>
                                <p className='top-text'>Passive income</p>
                            </div>
                        </div>
                        <div className="top-link" onClick={() => navigateTo("find")}>
                            <div className="top-image">
                                <img src={Location} alt="Buy a Velorex Crypto ATM" />
                            </div>
                            <div className="top-text-container">
                                <p className='top-bold'>Find ATM</p>
                                <p className='top-text'>Trade cash</p>
                            </div>
                        </div>
                    </div>
                    <img src={ATM1} className='atm' alt="Velorex ATM Front View" />
                    <h2 className='card-heading'>DeFi ATM</h2>
                    <p>Deposit or withdraw cash. The easiest and least restrictive fiat on/off ramp. Avoid relying on centralized banking services and failing institutions.</p>
                </div>
                {/* APP */}
                <div className="product-card">
                    <div className="top-left">
                        <div className="top-link" onClick={() => navigateTo("download")}>
                            <div className="top-image">
                                <img src={Download} alt="Buy a Velorex Crypto ATM" />
                            </div>
                            <div className="top-text-container">
                                <p className='top-bold'>Download App</p>
                                <p className='top-text'>Coming soon</p>
                            </div>
                        </div>
                    </div>
                    <img src={Phone} className='phone' alt="Velorex ATM Front View" />
                    <h2 className='card-heading'>Mobile dApp</h2>
                    <p>Keep track of your savings against inflation, view your wallet balances, and send/receive tokens using our all-in-one application.</p>
                </div>                
            </div>
            <div className="product-container">
                {/* POS */}
                <div className="product-card">
                        <div className="top-left">
                            <div className="top-link" onClick={() => navigateTo("call")}>
                                <div className="top-image">
                                    <img src={Call} alt="Buy a Velorex Crypto ATM" />
                                </div>
                                <div className="top-text-container">
                                    <p className='top-bold'>Book a Call</p>
                                </div>
                            </div>
                        </div>
                        <img src={Pos} className='pos' alt="Velorex ATM Front View" />
                        <h2 className='card-heading'>Retail solutions</h2>
                        <p>Pay or accept payments for goods and services in a variety of digital currencies using our innovative, lightweight, and user-friendly devices.</p>
                </div>
                {/* COMING SOON */}
                <div className="product-card faded">
                    <div className="top-left">
                        <div className="top-link">
                            <div className="top-image">
                                <img src={Gift} alt="Buy a Velorex Crypto ATM" />
                            </div>
                            <div className="top-text-container">
                                <p className='top-bold'>Coming soon</p>
                            </div>
                        </div>
                    </div>
                    <img src={Ball} className='mystery' alt="Velorex ATM Front View" />
                    <h2 className='card-heading'>New product</h2>
                    <p>To be announced.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Products