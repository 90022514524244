import React from 'react'
import {
    Lines,
  } from '../Assets/Image'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'

const NotFound = () => {
  return (
    <div>
        <img src={Lines} className='background-splash' alt="background colour splash" />
        <Navbar />
        {/* 404 not found */}
        <div className="footer-section">
            <div className="404-container" style={{marginTop: "200px", marginBottom: "800px"}}>
                <h1>404</h1>
                <h2>Page not found</h2>
                <p>Sorry, we couldn't find the page you were looking for.</p>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default NotFound