// TODO new old links

import React, { useState } from 'react'
import './StartNow.css'
import {
    Ball,
} from '../../Assets/Image'
import Widget from '../Widget/Widget'
import { FaRegTimesCircle } from 'react-icons/fa';

const StartNow = () => {

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible((prevVisible) => !prevVisible);
        scrollToTop();
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <section className="get-started-section">
            <div className="widget-container" style={{opacity: isVisible ? 'flex' : 'none', transform: isVisible ? 'translate(-50%, -50%)' : 'translate(-50%, -300%)'}}>
            <button className='nobuttonstyling' onClick={() => toggleVisibility()}><FaRegTimesCircle size={30} opacity={1} style={{marginBottom: "6px", color: "white", cursor: "pointer"}} /></button>
            <Widget id="widget"/>
        </div>
                <div className="content-block">
                    <div className="heading">
                        <img src={Ball} className='heading-ball' alt="" />
                        <h1>Start Now</h1>
                    </div>
                    <p className='content-text'>We've simplified the whole process to make everything familiar and easy to understand.<br/><br/>It only takes a couple of minutes to get started. We'll take you step-by-step.</p>
                    <p className='accent-text'>Which describes you best?</p>
                    <button className='btn-primary mtop'  onClick={() => toggleVisibility()}>I'm new to crypto</button>
                    <button className='btn-secondary mtop'  onClick={() => toggleVisibility()}>I have a wallet</button>
                </div>
            </section>
        </div>
    )
}

export default StartNow