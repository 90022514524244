import React from 'react'
import {
    Lines,
  } from '../Assets/Image'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import ATMPage from '../Components/ATM/ATM'

const ATM = () => {
  return (
    <div>
        <img src={Lines} className='background-splash' alt="background colour splash" />
        <Navbar />
        <ATMPage />
        <Footer />
    </div>
  )
}

export default ATM