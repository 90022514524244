import React, { useState } from 'react'
import './FAQ.css'
import { 
    Ball,
    Down,
    Copy,
} from '../../Assets/Image'

const FAQ = () => {

  const handleCopyAddress = () => {
    navigator.clipboard.writeText('0xc029A12e4A002c6858878FD9D3cc74E227cc2DDa');
  }

  const [accordions, setAccordions] = useState([
    { 
        question: 'What is Velorex?', 
        answer: (
            <>
              <p>
                Velorex is a decentralized finance technology company - we provide regular people with easy-to-use tools that
                help them save money and trade freely. Our area of expertise lies in the clean connection between the
                blockchain and the physical realm.
              </p>
              <p>
                We strive to build and install the infrastructure necessary for a more robust, scalable, secure, accessible,
                and free-flowing financial landscape. Our DeFi ATMs that allow you to instantly trade cash with digital
                currencies and vice-versa are proving extremely valuable in an environment filled with restrictions and
                discrimination towards where and how you spend your money.
              </p>
              <p>
                Our company is headquartered in Istanbul, Turkiye where we produce our ATM hardware - but we've got team
                members all around the globe working to expand our network and reach.
              </p>
            </>
          ),
    },
    { 
        question: 'What are the benefits?',
        answer: (
            <>
              <p>
              With inflation of some traditional fiat currencies running rampant our users are turning to other options for capital storage - specifically by converting fiat cash into digital currencies that are pegged to certain values - known as 'stablecoins'.
              </p>
              <p>
              Left and right we're noticing institutions are imposing rules that directly impacts your access to a plethora of digital currencies - avoid these restrictions by cutting out the middle-man and going direct to the source.
              </p>
              <p>
              When you trade using one of our ATMs you'll be interacting directly with decentralized exchanges and the blockchain - cutting out all of the interference and noise and giving you the smoothest exchange experience possible.
              </p>
            </>
          ),
    },
    { 
        question: 'How does the ATM work?',
        answer: (
            <>
              <p>
              The Velorex DeFi ATM was designed with simplicity in mind. The clean and simple user interface allows anyone to quickly buy or sell a variety of digital currencies using cash. Simply log in to the machine using your NFC membership card then insert cash and select which currency you want to buy. You can also sell digital currencies in a matter of minutes.              </p>
              <p>
              This product is designed to provide a money storage solution for the 'under-banked' population. By offering our machines for sale worldwide we allow anyone to become a 'node operator' in our network of ATMs. The owner of the machine will earn a small commission on each trade for hosting the service, and the resulting distributed network of machines and owners creates a decentralized and competitive service that dynamically adjusts to the local economics and best interests of the users.              </p>
              <p>
              Experience tells us that segregated responsibilities and liquidity are necessary to ensure the competition stays healthy and the end-user experience is always the most important consideration. With no single point of failure - the Velorex network of ATMs is able to provide a reliable and valuable solution to the problem.              </p>
                <p>
                If you're considering becoming and operator and owning your very own Velorex ATM to earn passive income through commission and provide an essential service to your community - visit our online shop or arrange a call with a team member today.
                </p>
            </>
          ),
    },
    { 
        question: 'Is it free or do I need to pay?',
        answer: (
            <>
              <p>
              We allow you to sign up and start using your account completely free of charge - and we cover the costs of your initial transactions so you can get used to the system without spending a penny. While we don't charge any fees for using all of the basic features of our app - you can upgrade to a premium account to get the most value out of our products.</p>
              <p>
              When using one of our ATMs you'll pay a small commission fee (decided by the owner of the machine - up to 3% maximum) which is paid directly to the machine owner in return for them providing the service, renting the space, maintaining the machine, etc.              </p>
              <p>
              Some digital currencies require a small transaction fee to be paid each time you use them. For example, you might pay a fee of $0.10 to send $1,000 USD to a relative in another country - but this will vary depending on which currency you're using. You can easily top up your wallet with a small amount to pay for these fees right away - or you can wait until you're ready.              </p>
                <p>
                Our app will outline all of your costs very clearly and you'll have the ultimate decision about what you're spending and when. The reason we pay these small transaction fees is because there is no central authority to manage everything - instead we pay a small amount to a decentralized network of people in return for their participation in providing the service.
                </p>
            </>
          ),
    },
    { 
        question: 'Where can I find a Velorex ATM?',
        answer: (
            <>
              <p>
              Velorex is a new company, and we've just begun installing our machines in public spaces. We're currently installing machines under our Velorex ATM company in the city of Istanbul, Turkiye. Our goal is to evenly distribute a network of machines across all major cities to provide a useful service with full coverage and accessibility.</p>
              <p>
              Simultaneously, we're offering our machines for sale on our online store. We accommodate for all currencies and locations worldwide - providing full inclusivity and opportunities to for passive income.              </p>
              <p>
              To find the exact location of your nearest Velorex ATM, or to request us to install a machine near you, view the map here.              </p>
            </>
          ),
    },
    { 
        question: 'How is my money stored and who has access?',
        answer: (
            <>
              <p>
              When you sign up to our platform a smart wallet will be created for you and attached to your account. The only person that is able to access your money is you - and the access keys are encrypted and stored on your device. For a full technical explanation about how your keys are generated, stored, or recovered - please view our documentation here. </p>
              <p>
              If you lose access to your account for any reason you can use the recovery options to restore your access with ease.</p>
            </>
          ),
    },
    { 
        question: 'What is a smart wallet?',
        answer: (
            <>
              <p>
              A smart wallet is like a digital piggy-bank for cryptocurrencies. Just as you keep coins or bills in a physical wallet, a smart wallet helps you store and manage digital money like USDT (a coin pegged to the value of the dollar), Bitcoin, Ethereum and many others. It's a safe and easy way to keep track of your digital coins and use them to buy things or send money to friends, all using your smartphone or computer.</p>
              <p>
              The most important differences about your smart wallet are your ultimate control over your funds, and the access to an incredibly diverse range of financial tools that - some of which give you benefits that are not possible with traditional finance.</p>
                <p>
                New technology can be daunting at first - but we're here to hold your hand along the way. If you're on the fence about getting started we recommend you test the system with a very small amount of funds so you can experience the benefits for yourself. 
                </p>
            </>
          ),
    },
    { 
      question: 'How do I participate in the sign up offer?',
      answer: (
          <>
            <p>
            To participate in the $5 sign up offer, create an account on Velorex ATM's platform during the specified promotional period. Please note that to claim the cash, you will need to visit one of our designated physical Velorex ATMs at a later date, following the provided instructions for token redemption. Ensure you keep track of the specified timeframe for claiming and cashing out these tokens from our ATMs.
            </p>
          </>
        ),
    },
    { 
      question: 'What are the conditions for claiming the sign up rewards?',
      answer: (
          <>
            <p>
            Eligible participants who create an account during the promotional period can claim the $5 worth of tokens. These tokens must be cashed out from a designated Velorex ATM within the specified timeframe after account creation. Please note that the Airdrop is limited, and tokens are subject to availability.
            </p>
          </>
        ),
    },
    { 
      question: 'How can I earn additional tokens through the Referral Airdrop Program?',
      answer: (
          <>
            <p>
            You can earn additional tokens through Velorex ATM's Referral Airdrop Program by inviting friends to join our platform. Refer 5 people to receive 1,000 VEX, the next 10 people for 2,500 VEX, and the final 25 people for 5,000 VEX. Your friends need to create accounts and fulfill certain criteria outlined in the Referral Program terms for you to qualify for the referral rewards. Please note that the Referral Airdrop Program is limited, and tokens are subject to availability. Please note that only active wallets will be eligible for the referral rewards. To be considered active, the wallet must have a minimum balance of $5 worth of VEX tokens. You can purchase VEX token directly from your wallet profile buy pressing 'VEX Swap', or from <button onClick={() => window.open('https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc029A12e4A002c6858878FD9D3cc74E227cc2DDa', '_blank')} className='nobuttonstyling underline'>PancakeSwap</button> or <button onClick={() => window.open('https://flooz.xyz/swap?refId=Y4oCTq&tokenAddress=0xc029A12e4A002c6858878FD9D3cc74E227cc2DDa&network=bsc', '_blank')} className='nobuttonstyling underline'>Flooz</button>.
            </p>
          </>
        ),
    },
    { 
      question: 'What actions do my referred friends need to take for me to receive referral rewards?',
      answer: (
          <>
            <p>
            For you to earn referral rewards, your referred friends must create accounts on Velorex ATM's platform and fulfill specific actions or meet eligibility criteria as described in the Referral Program terms. Once they have completed these requirements, you will be eligible to receive the corresponding number of tokens based on the number of successful referrals. 
            </p>
          </>
        ),
    },
    { 
      question: 'Are there any limitations or expiration dates for the referral rewards?',
      answer: (
          <>
            <p>
            The Referral Airdrop Program has a maximum limit for the number of eligible referrals. Additionally, tokens earned through referrals are subject to availability and may be changed or withdrawn by Velorex. It's recommended to review the Referral Program terms for detailed information about limitations and any expiration dates associated with the referral rewards.
            </p>
          </>
        ),
    },
    { 
      question: 'Where do I get my referral link?',
      answer: (
          <>
            <p>
              Open your account profile on our website from the main page or the menu after logging in. You'll see a section that says 'Earn free tokens' with a button that shows the amount of referrals you've currently made. Click the button to open up twitter with a pre-made post and share it with your friends, or use the copy button to copy the link.
            </p>
          </>
        ),
    },
    { 
      question: 'How much can I earn from referrals?',
      answer: (
          <>
            <p>
            You can earn additional tokens through Velorex ATM's Referral Airdrop Program by inviting friends to join our platform. Referring 5 friends will earn you 1,000 VEX tokens, another 10 friends for another 2,500 VEX tokens, and finally the next 25 friends for 5,000 VEX tokens. These referral rewards are based on the number of successful referrals meeting the criteria outlined in the Referral Program terms.
            </p>
          </>
        ),
    },
    { 
      question: 'Do I need to manually claim the airdrop?',
      answer: (
          <>
            <p>
              No, please don't take any action to claim the tokens - if you're eligible, we'll airdrop them to you directly in your new embedded wallet.
            </p>
          </>
        ),
    },
    { 
      question: 'When will the airdrop rewards arrive?',
      answer: (
          <>
            <p>
            The distribution of airdrop rewards will commence in batches starting from December 15, 2023. For the $5 Free Tokens Airdrop, once you've created an account during the promotional period, you'll be eligible to claim and cash out the tokens from one of our designated Velorex ATMs within the specified timeframe following the commencement date. Referral rewards will be processed in batches after the referred users fulfill the necessary actions and meet the Referral Program terms. Please note that the distribution timeline for each batch may vary and depend on processing times and token availability.      
            </p>
          </>
        ),
    },
    { 
      question: 'What is the contract address of the token?',
      answer: (
          <>
            <p>
              For security purposes, please always verify the token contract address using BSCScan or another explorer.
            </p>
            <button className='nobuttonstyling horizontal-flex' onClick={() => handleCopyAddress()}>
              <img className='copy-logo token-logo' src={Copy} alt='copy address' />
              <p className='mrgright'  style={{fontSize: "10px"}}>0xc029A12e4A002c6858878FD9D3cc74E227cc2DDa</p>
            </button>
          </>
        ),
    },
  ]);

  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <div>
      <section className='FAQ-section'>
        <h1 className='massive'>FAQ</h1>
        {accordions.map((item, index) => (
          <div className="accordion" key={index}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
              <div className="heading">
                <img src={Ball} className='heading-ball' alt="" />
                <h1>{item.question}</h1>
              </div>
              <img src={Down} className={openAccordion === index ? 'rotate' : ''} alt="" />
            </div>
            <div className={`accordion-text ${openAccordion === index ? 'open' : ''}`}>
              <p>{item.answer}</p>
            </div>
            <div className="linebreak" />
          </div>
        ))}
      </section>
    </div>
  );
};

export default FAQ;
