import React, { useState, useEffect, useRef } from 'react'
import './BuyTokens.css'
import {
    velorex,
    bnb,
    swap,
    Back
} from '../../Assets/Image'
import DebounceInput from 'react-debounce-input';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import BuyVEX from '../../Hooks/BuyVEX';
import SellVEX from '../../Hooks/SellVEX';

const urlDev = process.env.REACT_APP_DEV_URL
const urlProd = process.env.REACT_APP_PROD_URL
const urlEnv = process.env.NODE_ENV === "development" ? urlDev : urlProd;

const BuyTokens = ({ vexBalance, toggleVisibility }) => {

    const hideBuyTokens = () => {
        toggleVisibility(); // This will toggle the visibility state in the parent component
      };

    const { primaryWallet } = useDynamicContext();    
    const [currentBalance, setBalance] = useState(null);
    const [tokenAmount, setTokenAmount] = useState(0);
    const [baseAmount, setbaseAmount] = useState(0);
    const [gasPrice, setGasPrice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [nativeBalance, setNativeBalance] = useState(null);

    // Get the price of VEX and BNB using moralis
    const getPrices = async () => {
        setLoading(true);
        const response = await fetch(`${urlEnv}/api/vexPrice`);
        const { vexPrice } = await response.json();
        const response2 = await fetch(`${urlEnv}/api/bnbPrice`);
        const { bnbPrice } = await response2.json();
        if (tokenToSwapFrom.symbol === 'BNB') {
        tokenToSwapTo.priceInUsd = Number(vexPrice);
        tokenToSwapFrom.priceInUsd = Number(bnbPrice);
        } else if (tokenToSwapFrom.symbol === 'VEX') {
        tokenToSwapFrom.priceInUsd = Number(vexPrice);
        tokenToSwapTo.priceInUsd = Number(bnbPrice);
        }
        const gasAmount = 0.45 / Number(bnbPrice);
        setGasPrice(gasAmount);
        setLoading(false);
    };
    
    useEffect(() => {
        getPrices();
    }, [baseAmount, primaryWallet])
    
    const [tokenToSwapFrom, setTokenToSwapFrom] = useState({
        name: 'Binance Coin',
        symbol: 'BNB',
        priceInUsd : null,
        icon: bnb,
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    })
    const [tokenToSwapTo, setTokenToSwapTo] = useState({
        name: 'Velorex',
        symbol: 'VEX',
        priceInUsd : null,
        icon: velorex,
        address: '0xc029a12e4a002c6858878fd9d3cc74e227cc2dda',
        decimals: 9
    })

    // handle the input field change event
    const handlebaseAmountChange = (event) => {
        const tempBaseAmount = event.target.value;        
        setbaseAmount(Number(tempBaseAmount));
        const updatedTokenAmount = Number(tempBaseAmount) * tokenToSwapFrom.priceInUsd / tokenToSwapTo.priceInUsd;
        setTokenAmount(updatedTokenAmount);
    };

    // Switch the tokens
    const switchTokens = () => {
        const tempTokenFrom = { ...tokenToSwapFrom }; // Create a temporary copy of tokenToSwapFrom
        const tempTokenTo = { ...tokenToSwapTo }; // Create a temporary copy of tokenToSwapTo      
        // Update the states with the new values
        setTokenToSwapFrom(tempTokenTo);
        setTokenToSwapTo(tempTokenFrom);
        setbaseAmount(0);
        setTokenAmount(0);
    };

    const amountOutMin = 0;
    let isRequestPending = false;
    const intervalIdRef = useRef(null); // useRef to store the interval ID

    console.log(currentBalance);
    console.log(gasPrice);

    const check = currentBalance - (gasPrice * 1.2)

    console.log(check);

    const fetchBalance = async () => {
        if (isRequestPending) return;
        isRequestPending = true;
        if (tokenToSwapFrom.symbol === 'BNB') {
            if (primaryWallet) {
            const value = await primaryWallet.connector.getBalance();
            if (value !== currentBalance) {
                setBalance(value);
            }
            if (isNaN(value)) {
                setBalance(0);
            }
            }
        } else if (tokenToSwapFrom.symbol === 'VEX') {
            if (vexBalance !== currentBalance) {
                setBalance(vexBalance);
                const native = await primaryWallet.connector.getBalance();
                setNativeBalance(native);
            }
            if (isNaN(vexBalance)) {
                setBalance(0);
            }
        }      
        isRequestPending = false;
        setLoading2(false);
    };

    // Fetch the balance every 4 seconds
    useEffect(() => {
        const initialFetch = async () => {
          setLoading2(true);
          await fetchBalance();
          setLoading2(false);
        };
        initialFetch();
    
        // Clear previous interval using the useRef value
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
    
        intervalIdRef.current = setInterval(() => {
          fetchBalance();
        }, 4000);
    
        return () => {
          // Clear interval when the component unmounts
          clearInterval(intervalIdRef.current);
        };
      }, [primaryWallet, tokenToSwapFrom, vexBalance]);

  return (
    <div>
        <div className='wid'>
            <div className="backbutton">
                <button className='nobuttonstyling' onClick={hideBuyTokens}><img src={Back} className='token-logo fade' alt="" /></button>
            </div>
        <div className="spacer"></div>
        <div className="horizontal-flex nomrgbtm">
            <p className='pad bold'>You pay</p>
            <div className="currentbalance smaller fade"><button onClick={() => handlebaseAmountChange({"target": { "value": currentBalance - gasPrice - gasPrice }})} className='nobuttonstyling horizontal-flex'>Max: {!loading2 ? (<span className='bold'>{tokenToSwapFrom.symbol === "BNB" ? ( Number(currentBalance).toFixed(5) ) : ( Number(currentBalance).toFixed() )}</span>) : ( <div className="loading-skeleton-mini"></div> )}</button></div>
        </div>
        <div class="input-container">
            <DebounceInput debounceTimeout={500} type="number" min="0" id="baseAmount" value={baseAmount} placeholder="Amount" onChange={(event) => handlebaseAmountChange(event)} />
            <div className="horizontal-flex suffix">
                <img style={{backgroundColor: "white"}} src={tokenToSwapFrom.icon} alt="logo" width={24} height={24} />
                {tokenToSwapFrom.symbol}
            </div>
        </div>
        <p className='pad smaller faded'>~ ${(tokenToSwapFrom.priceInUsd * baseAmount).toFixed(2)}</p>
        <div className="swap-tokens fade"><button className='nobuttonstyling' onClick={switchTokens}><img src={swap} alt="" /></button></div>
        <p className='pad bold'>You get</p>
        <div class="input-container">
            {loading  &&
            <div className="loading-skeleton posabs"/>
            }
            <input type="text" id="tokenAmount" value={Number(tokenAmount).toLocaleString()} disabled />
            <div className="horizontal-flex suffix">
                <img style={{backgroundColor: "white"}} src={tokenToSwapTo.icon} alt="logo" width={24} height={24} />
                {tokenToSwapTo.symbol}
            </div>
        </div>
        <p className='pad smaller faded'>~ ${(tokenAmount * tokenToSwapTo.priceInUsd / 100 * 95).toFixed(2)} <span className='faded'>(excl. gas)</span></p>
        <div className="swap-tokens">
            {tokenToSwapFrom.symbol === 'BNB' && (
                <div className='stack'>
                <BuyVEX baseAmount={baseAmount} amountOutMin={amountOutMin} />
                {baseAmount > currentBalance - (gasPrice * 1.2) && (
                    <>
                    <div className="minispacer"></div>
                    <p className='pad smaller faded text-align'>Not enough BNB for gas</p>
                    </>
                )}
                </div>
            )
            }
            {tokenToSwapFrom.symbol === 'VEX' && (
                <div className='stack'>
                <SellVEX baseAmount={baseAmount} tokenAmount={tokenAmount} />
                {Number(nativeBalance) < 0.002 && (
                    <>
                    <div className="minispacer"></div>
                    <p className='pad smaller faded text-align'>Not enough BNB for gas</p>
                    </>
                )}
                </div>
            )
            }
        </div>
        </div>
    </div>
  )
}

export default BuyTokens