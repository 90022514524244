import React, { useState } from 'react'
import './Hero.css'
import {
    Hero
} from '../../Assets/Image'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { FaUser } from "react-icons/fa";
import { FaRegTimesCircle } from 'react-icons/fa';
import Widget from '../Widget/Widget';

const HeroSection = () => {
    const [showBuyTokens, setShowBuyTokens] = useState(false);
    const [showSendTokens, setShowSendTokens] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const { user } = useDynamicContext()

    const toggleVisibility = () => { 
        scrollToTop(); 
        setIsVisible((prevVisible) => !prevVisible);
        setShowBuyTokens(false);
        setShowSendTokens(false);
    };

    const handleCreateAccount = () => {
            toggleVisibility()
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

  return (
    <div>
        <div className="widget-container" style={{opacity: isVisible ? 'flex' : 'none', transform: isVisible ? 'translate(-50%, -50%)' : 'translate(-50%, -300%)'}}>
            <button className='nobuttonstyling' onClick={() => toggleVisibility()}><FaRegTimesCircle size={30} opacity={1} style={{marginBottom: "6px", color: "white", cursor: "pointer"}} /></button>
            <Widget showSendTokens={showSendTokens} setShowSendTokens={setShowSendTokens} showBuyTokens={showBuyTokens} setShowBuyTokens={setShowBuyTokens} id="widget"/>
        </div>
        <section className='hero-container'>
            <div className="hero">
                <div className="hero-text-container">
                    <div className="hero-text">
                        <h1>Secure, Instant & Decentralized Banking</h1>
                        <p>Empowering individuals to deposit and withdraw cash, convert to digital currencies, and gain access to a level financial playing-field.</p>
                        <p><span className='accent-text'>Get $5 free after you sign up<span className='light-text'>*</span></span></p>
                    </div>
                    <div className="hero-buttons">
                        {!user ? (
                        <button className='mainbutton' onClick={() => handleCreateAccount()}>Start now</button>
                        ) : (
                            <button className='btn-primary nomarginright' onClick={() => handleCreateAccount()}>
                            <div className="horizontal-flex">
                              <FaUser style={{ marginRight: "6px"}} />
                              My account
                            </div>
                          </button> 
                        )}
                    </div>
                </div>
                <div className="hero-image">
                    <img src={Hero} alt="hero" />
                </div>
            </div>
        </section>
        
    </div>
  )
}

export default HeroSection