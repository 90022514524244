// TODO github link

import React from 'react'
import './Socials.css'
import {
    Telegram,
    Twitter,
    Discord,
    Github,
    Linked
} from '../../Assets/Image'

const Socials = () => {

    const navigateTo = (url) => {
        window.open(`https://${url}`, '_blank')
    }

  return (
    <div>
        <section className='socials-section'>
            <div className="socials-container">
                <div className="socials-link" onClick={() => navigateTo("t.me/velorex")}>
                    <div className="socials-image">
                        <img src={Telegram} alt="Telegram group for Velorex ATMs" />
                    </div>
                    <div className="socials-text">
                        <p><span className='bold-text'>Telegram</span></p>
                        <p>Ask a question</p>
                    </div>
                </div>
                <div className="socials-link" onClick={() => navigateTo("twitter.com/velorexOfficial")}>
                    <div className="socials-image">
                        <img src={Twitter} alt="Telegram group for Velorex ATMs" />
                    </div>
                    <div className="socials-text">
                        <p><span className='bold-text'>Twitter</span></p>
                        <p>Follow back?</p>
                    </div>
                </div>
                <div className="socials-link" onClick={() => navigateTo("discord.gg/75zaXbX9")}>
                    <div className="socials-image">
                        <img src={Discord} alt="Telegram group for Velorex ATMs" />
                    </div>
                    <div className="socials-text">
                        <p><span className='bold-text'>Discord</span></p>
                        <p>Grab the mic</p>
                    </div>
                </div>
                <div className="socials-link" onClick={() => navigateTo("velorex.net")}>
                    <div className="socials-image">
                        <img src={Github} alt="Telegram group for Velorex ATMs" />
                    </div>
                    <div className="socials-text">
                        <p><span className='bold-text'>Github</span></p>
                        <p>Developer Andy</p>
                    </div>
                </div>
                <div className="socials-link" onClick={() => navigateTo("linkedin.com/company/velorex-atm/")}>
                    <div className="socials-image">
                        <img src={Linked} alt="Telegram group for Velorex ATMs" />
                    </div>
                    <div className="socials-text">
                        <p><span className='bold-text'>LinkedIn</span></p>
                        <p>Let's connect</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Socials