import React from 'react'
import './GetStarted.css'
import {
    Ball,
} from '../../Assets/Image'

const GetStarted = () => {

function navigateTo(path) {
    if (path === "call") {
        window.open(`https://calendly.com/velorex/30min`, "_blank")
    }
    if (path === "FAQ") {
        window.location.href = "/faq"
    }
}

  return (
    <div>
        <section className="get-started-section">
            <div className="content-block">
                <div className="heading">
                    <img src={Ball} className='heading-ball' alt="" />
                    <h1>What is Velorex?</h1>
                </div>
                <p className='content-text'>Use our ATMs to convert your cash into digital currencies - or purchase them directly within our App using your favourite payment methods.<br/><br/>The Velorex App will help you track and manage your balances easily, and understand how you're better-off by hedging against inflation.<br/><br/>We also provide a suite of white-label solutions for our clients. If you're interested in learning more about any of our products or services please book an appointment with a member of the team.</p>
                <button className='btn-primary mtop' onClick={() => navigateTo("call")}>Book a Call</button>
                <button className='btn-secondary mtop' onClick={() => navigateTo("FAQ")}>Read FAQs</button>
            </div>
        </section>
    </div>
  )
}

export default GetStarted