import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import FAQ from './Pages/FAQ';
import Contact from './Pages/Contact';
import ATM from './Pages/ATM';
import Welcome from './Pages/Welcome';
import Privacy from './Pages/Privacy';
import NotFound from './Pages/NotFound';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Lines,
} from './Assets/Image'
import {
  DynamicContextProvider
} from "@dynamic-labs/sdk-react-core";

import { EthersExtension } from "@dynamic-labs/ethers-v5";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { AlgorandWalletConnectors } from "@dynamic-labs/algorand";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import { FlowWalletConnectors } from "@dynamic-labs/flow";
import { StarknetWalletConnectors } from "@dynamic-labs/starknet";
import { CosmosWalletConnectors } from "@dynamic-labs/cosmos";
import { MagicWalletConnectors } from "@dynamic-labs/magic";
import { BloctoEvmWalletConnectors } from "@dynamic-labs/blocto-evm";

import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";

const urlDev = process.env.REACT_APP_DEV_URL
const urlProd = process.env.REACT_APP_PROD_URL
const urlEnv = process.env.NODE_ENV === "development" ? urlDev : urlProd;
const envId = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DYNAMIC_ENV_DEV : process.env.REACT_APP_DYNAMIC_ENV_PROD;


function App() {

  return (
    <DynamicContextProvider
      settings={{
        environmentId: envId,
        initialAuthenticationMode: 'connect-and-sign',
        walletConnectors: [
          EthereumWalletConnectors,
          AlgorandWalletConnectors,
          SolanaWalletConnectors,
          FlowWalletConnectors,
          StarknetWalletConnectors,
          CosmosWalletConnectors,
          MagicWalletConnectors,
          BloctoEvmWalletConnectors,
        ],
        extensions: [EthersExtension],
        eventsCallbacks: {
          onAuthSuccess: async (args) => {            
              let referral = null;
              // Get the referral code from the url params
              const urlParams = new URLSearchParams(window.location.search);
              const referralCode = urlParams.get('ref');
              // If there is a referral code, update the user with it
              referral = referralCode || null;
              // Sign the user up in the backend
              const response = await fetch(`${urlEnv}/api/users/signup`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  jwt: args.authToken,
                  referral,
                }),
              });
              await response.json();
              if (args.user.newUser === true) {
              window.location.href = '/welcome';
              }
          }
        },
      }}
    >
      <DynamicWagmiConnector>
        <AnimatePresence mode='wait' onExitComplete={() => window.scrollTo(0, 0)}>
          {/* <motion.div
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 300, opacity: 0 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          > */}
          <div>
            <Router>
              <div className="App appwrapper">
              <CookieConsent
                location="bottom"
                buttonText="Ok"
                style={{ background: "#fafafa", color: "#171717", fontFamily: "Montserrat" }}
                buttonStyle={{ color: "white", fontSize: "13px", fontWeight: "700", background: "#171717", fontFamily: "Montserrat", borderRadius: "6px", padding: "8px 16px" }}
                declineButtonStyle={{ color: "#171717", fontSize: "13px", fontWeight: "700", background: "#fafafa", fontFamily: "Montserrat", borderRadius: "6px", padding: "8px 16px", border: "1px solid #171717" }}
                declineButtonText="Decline"
                expires={150}
                enableDeclineButton
                onDecline={() => {
                  resetCookieConsentValue();
                  Cookies.remove();
                }}
              >
                This website uses cookies to enhance the user experience.
              </CookieConsent>
              <img src={Lines} className='background-splash' alt="background colour splash" />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/find" element={<ATM />} />
                  <Route path="/welcome" element={<Welcome />} />
                  <Route path="/legal" element={<Privacy />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </Router>
          </div>
          {/* </motion.div> */}
        </AnimatePresence>
      </DynamicWagmiConnector>
    </DynamicContextProvider> 
  );
}

export default App;
