import React from 'react'
import {
    Lines,
  } from '../Assets/Image'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import './Privacy.css'

const Contact = () => {
  return (
    <div>
        <img src={Lines} className='background-splash' alt="background colour splash" />
        <Navbar />
        <div className="privacy">
          <h2>Privacy Policy</h2>
          <p>This Privacy Policy outlines how Velorex ATMs (Velorex ATM Dis Ticaret Limited Sirketi, registered in Istanbul, Turkiye) ("we," "us," or "our") collects, uses, shares, and protects the information obtained from users ("you" or "your") of our website and services.</p>
          <div className="spacer"></div>
          <h1>Information Collection and Use</h1>
          <p>We collect personal information, including but not limited to, name, email address, contact information, and KYC data for the purpose of creating smart wallets and facilitating trading on our crypto ATM</p>
          <p>The information collected may be used for advertising targeting, service improvement, and compliance with legal requirements.</p>
          <p>We employ industry-standard security measures to protect your data; however, no method of transmission over the internet is entirely secure.</p>
          <div className="spacer"></div>
          <h1>Information Sharing</h1>
          <p>We may share your information with trusted third parties to provide services, comply with legal obligations, or enhance user experience.</p>
          <p>Your data might be disclosed in response to lawful requests by public authorities or law enforcement agencies.</p>
          <div className="spacer"></div>
          <h1>Information Retention</h1>
          <p>We retain your information for as long as necessary to provide services, comply with legal obligations, resolve disputes, and enforce agreements.</p>
          <div className="spacer"></div>
          <h1>Information Deletion</h1>
          <p>You may request the deletion of your data by contacting us at support@velorex.net</p>
          <div className="spacer"></div>
          <h1>Cookies and Tracking</h1>
          <p>We use cookies and similar tracking technologies to track user activity and collect information.</p>
          <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
          <div className="spacer"></div>
          <h1>Your Choices</h1>
          <p>You may opt-out of receiving promotional messages from us by following the instructions in those messages.</p>
          <p>You have the right to access, correct, or delete your personal information. Please contact us to exercise these rights.</p>
          <p>By using our services, you consent to the collection and use of your information as described in this Privacy Policy.</p>
          <div className="spacer"></div>
          <div className="spacer"></div>
          <h2>Terms of Service</h2>
          <p>By using our services, you agree to the following terms:</p>
          <p>Velorex ATMs (Velorex ATM Dis Ticaret Limited Sirketi, registered in Istanbul, Turkiye) ("we," "us," or "our") is not responsible for any loss caused by the use of our services.</p>
          <div className="spacer"></div>
          <h1>Account Registration</h1>
          <p>To use our services, you must create an account and provide accurate and complete information.</p>
          <p>You are responsible for maintaining the confidentiality of your account and password.</p>
          <p>You are responsible for all activities that occur under your account.</p>
          <p>You may not use our services:</p>
          <p>
            <ul>
              <li>For any illegal or unauthorized purpose.</li>
              <li>To violate any laws in your jurisdiction.</li>
              <li>To interfere with the operation of our services.</li>
              <li>To gain unauthorized access to our systems.</li>
              <li>To transmit viruses or other malicious code.</li>
              <li>To collect information about others without permission.</li>
              <li>To spam, harass, or impersonate others.</li>
              <li>To violate the privacy or intellectual property rights of others.</li>
            </ul>
          </p>
          <div className="spacer"></div>
          <h1>$5 Sign up offer</h1>
          <p>The $5 sign up offer is offered by Velorex ATM Dis Ticaret Limited Şirketi and is subject to the following terms.</p>
          <p><ul>
              <li>This offer is available to new users who create an account on Velorex ATM's platform during the promotional period.</li>
              <li>Participants in the Airdrop will receive $5 worth of tokens. To claim these Tokens, users must register on the Platform and follow the designated procedure to obtain the Tokens, which can be cashed out from a designated Velorex ATM.</li>
              <li>The Airdrop is limited in the number of participants that may receive a prize, and may end at any time without prior notice.</li>
              <li>Tokens are subject to availability and may be changed, replaced, or withdrawn by Velorex without notice.</li>
              <li>Velorex ATM is not liable for any technical issues, delays, or errors affecting the Airdrop. Participants agree that Velorex ATM, its affiliates, and respective directors, officers, employees, or agents shall not be held responsible or liable for any losses, damages, or claims arising out of or related to the Airdrop.</li>
              <li>Velorex ATM reserves the right to modify, suspend, or terminate the Airdrop or its terms at any time without prior notice.</li>
            </ul>
          </p>
          <div className="spacer"></div>
          <h1>Referral Airdrop Offer</h1>
          <p>Velorex offers a Referral Airdrop Program subject to the following terms.</p>
          <p className='bold'>Only active wallets will be eligible for an airdrop - please top up your account with $5 of VEX to activate the wallet, or use our built in swap to buy VEX with BNB</p>
          <p>Participants can earn additional Tokens by referring friends to the Platform. The referral rates are as follows:</p>
          <p><ul>
              <li>1st prize - 5 invites = 1,000 VEX</li>
              <li>2nd prize - 15 invites = 2,500 VEX</li>
              <li>3rd prize - 25 invites = 5,000 VEX</li>
            </ul>
          </p>
          <p>Token amounts are based on the equivalent dollar value of the token as of 9 Dec 2023 6am UTC and are subject to change in accordance with the token price</p>
          <p>Only active wallets are eligible for an airdrop - to activate your wallet please top up with at least $5 worth of VEX tokens.</p>
          <p><ul>
              <li>Users who are registered on the Platform and comply with the Referral Program's terms are eligible to participate.</li>
              <li>Participants can earn additional Tokens by referring friends to the Platform. The number of Tokens earned depends on the number of successful referrals.</li>
              <li>To qualify for Referral Program rewards, referred users must create an account, complete specific actions, and meet eligibility criteria as outlined in these Referral Program terms.</li>
              <li>Tokens are subject to availability and may be changed, replaced, or withdrawn by Velorex without notice.</li>
              <li>Limited Offer: The Referral Program is subject to a maximum number of eligible referrals. Velorex ATM reserves the right to end the Referral Program at any time without prior notice.</li>
              <li>Velorex ATM reserves the right to modify, suspend, or terminate the Airdrop or its terms at any time without prior notice.</li>
            </ul>
          </p>
          <p>By participating in these Airdrop offers, users agree to abide by the terms and conditions outlined above. These terms are subject to change at the discretion of Velorex ATM. Participants are encouraged to review the terms regularly for any updates or modifications.

Please note that the periodic airdrops will commence from December 15, 2023, without a disclosed timeframe for subsequent airdrop events.</p>
          <div className="spacer"></div>
          <h1>Account Suspension</h1>
          <p>We reserve the right to suspend or terminate your account at any time for any reason.</p>
          <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
          <div className="spacer"></div>
          <h1>Changes to Terms of Service</h1>
          <p>We reserve the right to modify or replace these terms at any time.</p>
          <p>By continuing to use our services after revisions become effective, you agree to be bound by the revised terms.</p>
          <div className="spacer"></div>
          <h1>Changes to Services</h1>
          <p>We reserve the right to modify or discontinue our services at any time without notice.</p>
          <p>We shall not be liable to you or any third party for any modification, price change, suspension, or discontinuance of our services.</p>
          <div className="spacer"></div>
          <h1>Disclaimer of Warranties</h1>
          <p>Our services are provided on an "as is" and "as available" basis without any warranty or condition.</p>
          <p>We do not warrant that our services will be uninterrupted, timely, secure, error-free, accurate, reliable, meet your expectations, error-corrected, virus-free, compatible with other software/hardware, fit for any particular purpose, available in all countries, or free from unauthorized access, use, modification, disclosure, destruction, loss, alteration, deletion, damage, corruption, loss of data, information, content, materials, resources, revenue, or profits.</p>
          <div className="spacer"></div>
          <h1>Limitation of Liability</h1>
          <p>We shall not be liable to you or any third party for any direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, information, content, materials, resources, revenue, or other intangible losses.</p>
          <p>Please note that nothing on our website constitutes financial advice or an offer for investment. There is no guarantee of profits or returns by purchasing any tokens or utilizing our services. We will not be liable for any loss of funds, damages, or adverse outcomes resulting from trades made using our services. We solely provide the means to facilitate trading, and users assume full responsibility for compliance with laws and regulations while using our services.</p>
          <div className="spacer"></div>
          <h1>Velorex token</h1>
          <p>Our token is not an investment and you should not expect and monetary gains by purchasing or holding it. It is specifically designed and intended to function solely as a governance and utility token within our ecosystem of products and services. This token does not represent ownership in the company, nor does it entitle holders to any dividends or profit-sharing. Its primary purpose is to facilitate governance mechanisms and provide utility within our platform.</p>
          <p>Our token is not intended to be a digital currency, security, commodity, or any other kind of financial instrument.</p>
          <p>Our token serves as the cornerstone of our ecosystem, operating as a distinct governance and utility token designed to empower users within our platform. As a governance token, it facilitates community-driven decision-making processes, enabling holders to participate in voting on key proposals, protocol upgrades, and ecosystem developments.

Beyond its governance role, our token functions as a utility within our suite of products and services, offering various benefits such as access to premium features, discounted fees, incentivized rewards, or exclusive privileges within our decentralized network.

It's important to note that our token does not constitute or represent any form of ownership or equity in the company, and it does not grant dividend rights or profit-sharing. Instead, its primary purpose is to foster engagement, incentivize active participation, and enhance the overall user experience within our ecosystem.

As a utility token, its value is intrinsically tied to its utility and demand within our platform and is not intended for speculative investment purposes. Holders of our token acknowledge and understand that its value may fluctuate based on market dynamics and user adoption within our ecosystem.

We emphasize that our token complies with regulations and is not classified as a security token. It does not offer any financial stake or securities-like benefits. Users are encouraged to use the token solely within the intended scope of our platform and understand that its utility is subject to change based on the evolution of our ecosystem and user requirements.

We're committed to transparency and compliance, continually striving to enhance the functionalities and utility of our token while adhering to legal and regulatory standards.</p>
        </div>
        
        <Footer />
    </div>
  )
}

export default Contact