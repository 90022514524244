import React, { useEffect } from 'react'
import './Chat.css'
import {
    Team,
    Ball
  } from '../../Assets/Image'

const urlDev = process.env.REACT_APP_DEV_URL
const urlProd = process.env.REACT_APP_PROD_URL
const urlEnv = process.env.NODE_ENV === "development" ? urlDev : urlProd;

const Chat = () => {

    window.addEventListener("load", () => {
        setTimeout(() => {
          const href = window.location.href;
          if (href.includes("message=true")) {
            const element = document.querySelector(".message-container");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }
        }, 100); // Adjust the delay time if needed
      });

      const clearInputs = () => {
        document.getElementById('name').value = '';
        document.getElementById('email').value = '';
        document.getElementById('message').value = '';
      };

      const changeSendButton = () => {
        document.getElementById('sendbtn').innerHTML = 'Sent!';
        document.getElementById('sendbtn').disabled = true;
      };

      const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };         

    const handleSubmit = async (e) => {
        e.preventDefault();
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const message = document.getElementById('message').value;
      
        try {
          const response = await fetch(`${urlEnv}/send-form`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, message }),
          });
      
          if (response.ok) {
            console.log('Email sent successfully');
            clearInputs();
            changeSendButton();
            scrollToTop();
          } else {
            // Handle errors
            console.error('Failed to send email');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
  return (
    <div>
        <section className='chat-section'>
            <div className="chat-container">
                <div className="heading">
                    <img src={Ball} className='heading-ball' alt="" />
                    <h1>Chat with us</h1>
                </div>
                <p>Book a Zoom meeting with a member of the team now</p>
                <div className="spacer-small"/>
                <button className='btn-primary' onClick={() => window.open(`https://calendly.com/velorex/30min`, "_blank")}>Book a Call</button>
            </div>
            <div className="team-container">
                <img src={Team} className='teamimg' alt="" />
                <div className="team-list">
                    <div className="team-link">
                        <h2 className='reveal'>Todd</h2>
                        <p className='pp'>Chief Executive Officer,<br/>Technology Officer</p>
                    </div>
                    <div className="team-link">
                        <h2 className='reveal'>Lukas</h2>
                        <p className='pp'>Chief Operations Officer</p>
                    </div>
                    <div className="team-link">
                        <h2 className='reveal'>Anıl</h2>
                        <p className='pp'>Product Manager,<br/>Junior Developer</p>
                    </div>
                    <div className="team-link">
                        <h2 className='reveal'>Michal</h2>
                        <p className='pp'>Chief Logistics Officer,<br/>Internal Relations</p>
                    </div>
                    <div className="team-link">
                        <h2 className='reveal'>José</h2>
                        <p className='pp'>Public Relations,<br/>Spokesperson</p>
                    </div>
                    <div className="team-link">
                        <h2 className='reveal'>Rick</h2>
                        <p className='pp'>Advisor,<br/>Junior Developer</p>
                    </div>
                </div>
            </div>
            <div className="message-container">
                <div className="heading">
                    <img src={Ball} className='heading-ball' alt="" />
                    <h1>Leave a message</h1>
                </div>
                <p className='content-text'>We'll respond as soon as possible</p>
            </div>
            <form onSubmit={handleSubmit} className='contact-form'>
                <div className="contact-container">
                    <div className="form-group">
                        <label className='h2' htmlFor="name">Name</label>
                        <input className='footer-input' type="text" name='name' id='name' placeholder='Your name' />
                    </div>
                    <div className="spacer"></div>
                    <div className="form-group">
                        <label className='h2' htmlFor="email">Email</label>
                        <input className='footer-input' type="email" name='email' id='email' placeholder='Your email' />
                    </div>
                    <div className="spacer"></div>
                    <div className="form-group">
                        <label className='h2' htmlFor="message">Message</label>
                        <textarea className='footer-input2' name="message" id="message" cols="30" rows="10" placeholder='Your message'></textarea>
                    </div>
                    <div className="spacer"></div>
                    <button id="sendbtn" className='btn-primary'>Submit</button>
                </div>
            </form>
        </section>
    </div>
  )
}

export default Chat