import React from 'react'
import './ChoiceControl.css'
import {
    Trade,
    TopATM
} from '../../Assets/Image'
const ChoiceControl = () => {
  return (
    <div>
        <section className='choice-control-section'>
            <div className="section-container">
                <div className="product-card">
                    <img src={Trade} className='trade' alt="" />
                    <h1 style={{marginRight: "auto"}}>Free choice</h1>
                    <p>Take control of your finances by freely choosing and swiftly changing between currencies.<br/><br/>Seamlessly switch between traditional fiat and cryptocurrencies, empowering yourself with the flexibility to adapt to your evolving financial needs effortlessly.</p>
                </div>
                <div className="product-card">
                    <img src={TopATM} className='topatm' alt="" />
                    <h1 style={{marginRight: "auto"}}>Full control</h1>
                    <p>By embracing non-custodial solutions, you retain complete control and ownership of your assets.<br/><br/>Experience the peace of mind that comes from knowing your funds are secure, and accessible only by you.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ChoiceControl