import React, { useState, useEffect } from 'react'
import {
    Lines,
    Ball,
    Copy,
    Telegram,
    Gift
  } from '../Assets/Image'
import './Welcome.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import Widget from '../Components/Widget/Widget'; 
import { FaRegTimesCircle } from 'react-icons/fa';
import Footer from '../Components/Footer/Footer';

const urlDev = 
    "http://localhost:3999";

const urlProd =
    "https://defi-atm.co.uk";

const urlEnv = process.env.NODE_ENV === "development" ? urlDev : urlProd;

const Welcome = () => {
  const { user } = useDynamicContext();
  const [isVisible, setIsVisible] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [showBuyTokens, setShowBuyTokens] = useState(false);
  const [showSendTokens, setShowSendTokens] = useState(false);

  const handleCopyCode = async () => {
    const textCopy = await getReferralCode();
    navigator.clipboard.writeText(textCopy);  
    toast.success("Copied to clipboard!", {
        position: 'bottom-center',
    });      
  }

  // Get the users referral code from the backend
  const getReferralCode = async () => {
    const userId = user?.userId;
    try {
        const response = await fetch(`${urlEnv}/api/users/referral`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId })
        });
        const referralCode = await response.json();
        setReferralCode(referralCode.referral)
        // Attach the referral code to the website URL and return it
        const url = `https://velorex.net/?ref=${referralCode.referral}`;
        return url;
    } catch (error) {
        console.log(error);
        return null;
    }
  }

  const toggleVisibility = () => { 
    scrollToTop(); 
    setIsVisible((prevVisible) => !prevVisible);
    setShowBuyTokens(false);
    setShowSendTokens(false);
};

const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: "smooth"
  });
};

  const shareContent = async () => {
    // If the user is on a mobile device, use the native share API
    // Check the device type
//     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
//     if (isMobile) {
//     if (navigator.share) {
//       try {
//         await navigator.share({
//           title: 'Get free VEX tokens',
//           text: 'Sign up to the app now and earn free VEX tokens. Cash them out using our DeFi Crypto ATM! #velorex',
//           url: `https://velorex.net/?ref=${referralCode}`,
//         });
//       } catch (error) {
//         console.error('Error sharing:', error);
//         // Handle error if sharing fails
//       }
//     }
//  } else {
  const tweetOptions = [
    `💰 Sign up now & earn free crypto!\n💳 Buy $VEX before anyone else!\n\n✨ Create a free account in seconds:\n🌐 https://velorex.net/?ref=${referralCode}\n\n#Crypto #Velorex #CryptoRewards #Bitcoin #Binance\n\nhttps://youtube.com/shorts/LmDaJFfzHGc?si=TAviNUTie-qoJoln`,
    `🟢 Activate your $VEX wallet to earn free crypto\n\n💳 Top up $5 of VEX to become eligible for the airdrop\n\n✨ Create a free account in seconds:\n🌐 https://velorex.net/?ref=${referralCode}\n\n#Crypto #Velorex #CryptoRewards #airdrop #airdrops\n\nhttps://www.youtube.com/shorts/tKP5nkWi-80`,
  ];
    // const selectedTweet = tweetOptions[Math.floor(Math.random() * tweetOptions.length)];
    const selectedTweet = tweetOptions[1];
    const tweetContent = encodeURIComponent(selectedTweet);
    window.open(`https://twitter.com/intent/tweet?text=${tweetContent}`);
// }
  };

  const openWallet = () => {
    if (user) {
      toggleVisibility()
    } else {
      toast.error('Please log in to access your wallet', {
        position: "top-right",
        autoClose: 3700,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        });
      setTimeout(() => {
        toggleVisibility()
      }, 3000);
    }
  }

  const openTelegramNewPage = () => {
    window.open('https://t.me/velorex', '_blank');
  }

  useEffect(() => {
    getReferralCode();
  }, [user])

  return (
    <div className='page'>
        <div className="widget-container" style={{opacity: isVisible ? 'flex' : 'none', transform: isVisible ? 'translate(-50%, -50%)' : 'translate(-50%, -300%)'}}>
            <button className='nobuttonstyling' onClick={() => toggleVisibility()}><FaRegTimesCircle size={30} opacity={1} style={{marginBottom: "6px", color: "white", cursor: "pointer"}} /></button>
            <Widget showSendTokens={showSendTokens} setShowSendTokens={setShowSendTokens} showBuyTokens={showBuyTokens} setShowBuyTokens={setShowBuyTokens} id="widget"/>
        </div>
        <img src={Lines} className='background-splash2' alt="background colour splash" />
        <section className='welcome-container'>
            <div className="welcome">
                <>
                <div className="welcome-text-container">
                  <div className="welcome-heading">
                  <div className="heading">
                        <img src={Ball} className='heading-ball' alt="" />
                        <h1>Thanks for joining</h1>
                    </div>
                  </div>
                  <div className="minispacer"></div>
                    <div className="welcome-text whitebox">
                    <p>Please wait for an email regarding the airdrop. Check the FAQ <button className='nobuttonstyling underline' onClick={() => window.location.href="/faq"}>here</button> for more info.</p>

                        <p>Our mobile app is still in production - we'll send you an email when it's live on the app stores.</p>
                        <p><span className='accent-text smaller'>Order your physical membership card<span className='thinner'> (coming soon)</span></span></p>
                    </div>
                    <div className="minispacer"></div>
                    <div className="welcome-text whitebox">
                    <div className="minispacer"></div>
                      <div className="">
                        <div className="welcome-buttons">
                            <button className='btn-welcome' onClick={() => window.location.href = "/"}>Home</button>
                            <button className='btn-welcome-3' onClick={() => { 
                              openWallet()
                              }
                            }>View profile</button>
                        </div>
                        {/* <div className="welcome-buttons">
                            <button disabled={true} className='btn-welcome-2 faded' onClick={() => window.location.href = "/learn"}>Order card</button>
                        </div> */}
                        </div>
                      </div>
                      <div className="minispacer"></div>
                      <div className="welcome-text whitebox purpleborder">
                        <div className="horizontal-flex space-evenly padleftright">
                        <div className="flex">
                          <img className='token-logo mrgright' src={Gift} alt='Free Velorex tokens' /><p>Earn free tokens<span className='smaller faded'>*</span></p>
                        </div>
                        <div className="minispacer"></div>
                        <div className="flex">
                        <button onClick={shareContent} className='btn-secondary2 vw padd'><span className='faded'>Share</span></button><img className='token-logo copy-logo' style={{cursor: "pointer"}} onClick={() => handleCopyCode()} src={Copy} alt="" /></div>
                        </div> 
                        </div>
                    <div className="minispacer"></div>
                    <div className="welcome-text whitebox purpleborder">
                      {/* Telegram link and icon */}
                      <div className="flex">
                      <button onClick={() => openTelegramNewPage()} className='nobuttonstyling mrgright horizontal-flex'>
                        <img src={Telegram} width={40} height={40} alt='velorex telegram'/>
                        <p>Join our community</p>
                      </button>
                      </div>
                    </div>
                </div>
                <p style={{fontSize: "11px", opacity: "0.5", maxWidth: "300px", marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>* Limited offer subject to availability. By participating you agree to the terms <button className='nobuttonstyling underline' onClick={() => window.location.href="/legal"}>here</button></p>
                </> 
            </div>
        </section>
        <ToastContainer />
    </div>
  )
}

export default Welcome