import React from 'react'
import {
    Lines,
  } from '../Assets/Image'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import Chat from '../Components/Chat/Chat'

const Contact = () => {
  return (
    <div>
        <img src={Lines} className='background-splash' alt="background colour splash" />
        <Navbar />
        <Chat />
        <Footer />
    </div>
  )
}

export default Contact