import React, { useState, useEffect } from 'react'
import './SendTokens.css'
import {
    velorex,
    Back
} from '../../Assets/Image'
import DebounceInput from 'react-debounce-input';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import SendVEX from '../../Hooks/SendVEX';

const urlDev = process.env.REACT_APP_DEV_URL
const urlProd = process.env.REACT_APP_PROD_URL
const urlEnv = process.env.NODE_ENV === "development" ? urlDev : urlProd;

const SendTokens = ({ toggleVisibility }) => {

    const hideSendTokens = () => {
        toggleVisibility(); // This will toggle the visibility state in the parent component
      };

    const { primaryWallet } = useDynamicContext();
    const primaryWalletAddress = primaryWallet?.address    
    const [tokenAmount, setTokenAmount] = useState(0);
    const [currentBalance, setBalance] = useState(null);
    const [sendToWallet, setSendToWallet] = useState(null);
    const [loading2, setLoading2] = useState(false);
    const [nativeBalance, setNativeBalance] = useState(null);

    const handleAmountChange = (event) => {
        setTokenAmount(event.target.value);
    }

    const inputMax = () => {
        setTokenAmount(Math.floor(Number(currentBalance)));
    }

    useEffect(() => {
        const init = async () => {
            setLoading2(true);
            await getTokenBalance();
            setLoading2(false);
            const native = await primaryWallet.connector.getBalance();
            setNativeBalance(native);
        }
        init();
    } , [primaryWalletAddress])

    // Get the token balance from the backend
    const getTokenBalance = async () => {
        let userWallets = [];
        userWallets.push(primaryWalletAddress);
        const response = await fetch(`${urlEnv}/api/tokenBalances`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ wallets: userWallets })
        });
        const { totalBalance } = await response.json();
        console.log(totalBalance);
        setBalance(totalBalance);
    }
    
  return (
    <div>
        <div className='wid'>
            <div className="backbutton">
                <button className='nobuttonstyling' onClick={hideSendTokens}><img src={Back} className='token-logo fade' alt="" /></button>
            </div>
            <div className="spacer"></div>

            <div className="horizontal-flex nomrgbtm">
                <p className='pad bold'>Wallet Address</p>
            </div>

            <div class="input-container">
                <DebounceInput debounceTimeout={500} className="input2" type="text" id="baseAmounta" value={sendToWallet} placeholder="0x..." onChange={(event) => setSendToWallet(event.target.value)} />
            </div>

            <div className="spacer"></div>
            <div className="horizontal-flex nomrgbtm">
                <p className='pad bold'>Amount</p>
                <div className="currentbalance smaller fade"><button onClick={() => inputMax()} className='nobuttonstyling horizontal-flex'>Max: {!loading2 ? ( <span className='bold'>{Math.floor(Number(currentBalance))}</span>) : ( <div className="loading-skeleton-mini"></div> )}</button></div>
            </div>

            <div class="input-container">
                <DebounceInput debounceTimeout={500} type="number" min="0" id="baseAmount" value={tokenAmount} placeholder="Amount" onChange={(event) => handleAmountChange(event)} />
                <div className="horizontal-flex suffix">
                    <img style={{backgroundColor: "white"}} src={velorex} alt="logo" width={24} height={24} />
                    VEX
                </div>
            </div>     

            <div className="swap-tokens stack">
                <SendVEX amount={tokenAmount} sendTo={sendToWallet} />
                {Number(nativeBalance) < 0.002 && (
                    <>
                    <div className="minispacer"></div>
                    <p className='pad smaller faded text-align'>Not enough BNB for gas</p>
                    </>
                )}
            </div>
             
        </div>
    </div>
  )
}

export default SendTokens