import { usePrepareContractWrite, useContractWrite, useWaitForTransaction, useContractRead } from 'wagmi'
import pancakeABI from '../Components/ABI/pancakeABI.json'
import velorexABI from '../Components/ABI/velorexABI.json'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SellVEX = ({baseAmount, tokenAmount}) => {

  let myFormattedBaseAmount = baseAmount * 10**9;
  myFormattedBaseAmount = myFormattedBaseAmount.toFixed(0);

  let myFormattedTokenAmount = tokenAmount * 10**18 / 100 * 90;
  myFormattedTokenAmount = myFormattedTokenAmount.toFixed(0);

    const { primaryWallet } = useDynamicContext();
    const primaryWalletAddress = primaryWallet?.address
    const [approved, setApproved] = useState(false);
    const [nativeBalance, setNativeBalance] = useState(null);

    const allowance = useContractRead({
      address: '0xc029a12e4a002c6858878fd9d3cc74e227cc2dda',
        abi: velorexABI,
        functionName: 'allowance',
        args: [
            primaryWalletAddress,
            "0x10ed43c718714eb63d5aa57b78b54704e256024e",
        ],
        watch: true,
    })

    useEffect(() => {
      const allowanceNumber = parseInt(allowance.data);
      if (Number(baseAmount) < allowanceNumber) {
        setApproved(true);
      } else {
        setApproved(false);
      }
    }, [allowance, baseAmount])

    useEffect(() => {
      const fetchBalance = async () => {
        if (primaryWallet) {
          const value = await primaryWallet.connector.getBalance();
          setNativeBalance(value);
        }
      };
      fetchBalance();
    }, [primaryWallet]);

    // Approve VEX to be sold
    const { config: approveConfig, error: approvePrepareError, isError: approveIsPrepareError } = usePrepareContractWrite({
        address: '0xc029a12e4a002c6858878fd9d3cc74e227cc2dda',
        abi: velorexABI,
        functionName: 'approve',
        args: [
            "0x10ed43c718714eb63d5aa57b78b54704e256024e",
            "115792089237316195423570985008687907853269984665640564039457584007913129639935",
        ]
    })
    const { data: approveData, write: approveWrite } = useContractWrite(approveConfig)
    const { isLoading: approveIsLoading, isSuccess: approveIsSuccess } = useWaitForTransaction({
        hash: approveData?.hash,
    })

    // Sell VEX for BNB
    const { config } = usePrepareContractWrite({
        address: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
        abi: pancakeABI,
        functionName: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
        args: [
            myFormattedBaseAmount,
            myFormattedTokenAmount,
            ["0xc029a12e4a002c6858878fd9d3cc74e227cc2dda", "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"],
            primaryWalletAddress,
            Math.floor(Date.now() / 1000) + 60 * 20,
        ]
    })
    const { data, write: sellWrite, isError, isPrepareError } = useContractWrite(config)
    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
    })

    useEffect(() => {
      if (isSuccess) {
        toast.success('Success', {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true
            });
      }
    }, [isSuccess])

return (
    <div>
      {approved ? (
        <>
          {isSuccess ? (
            <button disabled className='mainbutton leftright'>Success</button>
          ) : (
          <button className='mainbutton leftright' disabled={!sellWrite || isLoading || isPrepareError} onClick={() => sellWrite()}>
            {isLoading ? 'Swapping' : 'Sell'}
          </button>
          )}
        </>
      ) : (
        <>
        { nativeBalance > 0.002 ? (
          <button className='mainbutton leftright' disabled={!approveWrite || approveIsLoading || approveIsPrepareError || baseAmount === 0 } onClick={() => approveWrite()}>
            {approveIsLoading ? 'Approving' : 'Approve'}
          </button>
        ) : (
          <>
          <button disabled className='mainbutton leftright'>Insufficient BNB</button>
          </>
        )}
        </>
      )}
  </div>
)
}

export default SellVEX