import React from 'react'
import Navbar from '../Components/Navbar/Navbar';
import Socials from '../Components/Socials/Socials';
import Footer from '../Components/Footer/Footer';
import FAQ from '../Components/FAQ/FAQ';

const Home = () => {
  return (
    <div>
        <Navbar />
        <FAQ />
        <Socials />
        <div className="spacer"></div>
        <Footer />
    </div>
  )
}

export default Home