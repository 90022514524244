import React from 'react'
import './Footer.css'
import {
    Faded,
    Ball
} from '../../Assets/Image'
import { FaMapMarkerAlt } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const urlDev = process.env.REACT_APP_DEV_URL
const urlProd = process.env.REACT_APP_PROD_URL
const urlEnv = process.env.NODE_ENV === "development" ? urlDev : urlProd;

const Footer = () => { 

    const handleSubmitFooter = async (e) => {
        e.preventDefault();
        const email = document.getElementById('emailf').value;

        if (!email) {
            toast.error("Please enter your email", {
                position: 'bottom-center',
            });
            return;
        }

        try {
          const response = await fetch(`${urlEnv}/save-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
          });
          console.log(response);

            // Clear the input field after submission and change the button text to Done
            document.getElementById('emailf').value = '';
            document.getElementById('emailbtn').innerHTML = 'Sent!';
            document.getElementById('emailbtn').disabled = true;

        } catch (error) {
          console.error('Error:', error);
        }
    };

    const navigateTo = (location) => {
        window.location.href = location
    }

  return (
    <div className='footer-box'>
        <ToastContainer />
        <section className='footer-section'>
            <div className="footer-container">
                <div className="heading">
                    <img src={Ball} className='heading-ball' alt="" />
                    <h1>Join the club</h1>
                </div>
                    <p className='content-text'>Get exclusive updates and offers that you can't find anywhere else.</p>
                <form onSubmit={handleSubmitFooter}>
                    <div className="footer-inputs">
                        <input type="email" id='emailf' placeholder='Enter your email' className='footer-input' />
                        <button id='emailbtn' className='btn-primary'>Subscribe</button>
                    </div>
                </form>
                <div className="footer-links">
                    <p className='text fade' onClick={() => navigateTo("/faq")}>FAQ</p>
                    <p className='text fade'  onClick={() => navigateTo("/contact")}>Contact</p>
                    <p className='text fade' onClick={() => navigateTo("/legal")}>Legal</p>
                    <div className="horizontal-flex fade" onClick={() => navigateTo("/find")}>
                        <FaMapMarkerAlt /> 
                        <p className='text'>ATM</p>
                    </div>
                </div>
                <p style={{fontSize: "11px", opacity: "0.7"}}>* Limited offer subject to availability. After creating an account and ordering and receiving your membership card you can visit any of our ATMs to claim your free cash. Actual amount dispensable may vary based upon the denominations available at the location. All rights reserved. For more information about our offers please see the terms <button className='nobuttonstyling underline' onClick={() => window.location.href="/legal"}>here</button></p>
            </div>
        </section>
        <img src={Faded} className='background-splash-bottom' alt="background colour splash" />
    </div>
  )
}

export default Footer