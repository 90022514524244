// TODO news links to be updated

import React from 'react'
import './News.css'
import {
    Ball,
    Blog,
    Up
} from '../../Assets/Image'

const News = () => {

    const navigateTo = (url) => {
        window.open(`https://${url}`, '_blank')
    }
    
  return (
    <div>
        <section className='news-section'>
            <div className="news">
                <div className="heading">
                    <img src={Ball} className='heading-ball' alt="Ball icon Velorex crypto ATM" />
                    <h1>Latest News</h1>
                </div>
                <img src={Blog} className='newsimg' alt="Velorex blog" />
                <div className="news-container">
                    <div className="news-link" onClick={() => navigateTo("medium.com/@VelorexATM/dc15d0c11dfe")}>
                        <div className="news-item">
                            <p><span className='read'>2 min read</span></p>
                            <p><span className='title'>DeFi vs. Inflation</span></p>
                            <div className="horizontal-flex">
                                <p><span className='read'>Read more</span></p>
                                <img src={Up} className='up' alt="up arrow Velorex crypto ATM" />
                            </div>
                        </div>
                    </div>
                    <div className="news-link" onClick={() => navigateTo("medium.com/@VelorexATM/eebb6ad52d78")}>
                        <div className="news-item">
                            <p><span className='read'>3 min read</span></p>
                            <p><span className='title'>A Revolutionary Force</span></p>
                            <div className="horizontal-flex">
                                <p><span className='read'>Read more</span></p>
                                <img src={Up} className='up' alt="up arrow Velorex crypto ATM" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="news-link" onClick={() => navigateTo("t.me/velorex")}>
                        <div className="news-item">
                            <p><span className='read'>3 min read</span></p>
                            <p><span className='title'>Unshackling Financial Frontiers</span></p>
                            <div className="horizontal-flex">
                                <p><span className='read'>Read more</span></p>
                                <img src={Up} className='up' alt="up arrow Velorex crypto ATM" />
                            </div>
                        </div>
                    </div> */}
                    <div className="news-link" onClick={() => navigateTo("medium.com/@VelorexATM/181155c046d2")}>
                        <div className="news-item">
                            <p><span className='read'>2 min read</span></p>
                            <p><span className='title'>The Path to Simplified Decentralization</span></p>
                            <div className="horizontal-flex">
                                <p><span className='read'>Read more</span></p>
                                <img src={Up} className='up' alt="up arrow Velorex crypto ATM" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default News