// TODO cache the referral code in local storage
import React, { useState, useEffect } from 'react'
import './Widget.css'
import {
    Copy,
    Gift,
    velorex,
    send
} from '../../Assets/Image'
import { DynamicEmbeddedWidget, useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BuyTokens from '../BuyTokens/BuyTokens';
import SendTokens from '../SendTokens/SendTokens';

const urlDev = process.env.REACT_APP_DEV_URL
const urlProd = process.env.REACT_APP_PROD_URL
const urlEnv = process.env.NODE_ENV === "development" ? urlDev : urlProd;

function num(x) {
    const num = Number(x);
    if (isNaN(num)) return 0;
    
    const parts = num.toString().split('.');
    // Formatting the integer part
    let integerPart = parts[0];
    if (integerPart.length >= 4) {
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Joining integer part and decimal part (if exists) with '.'
    if (parts.length === 2) {
        return `${integerPart}.${parts[1]}`;
    } else {
        return integerPart;
    }
}

const Widget = ({ showBuyTokens, setShowBuyTokens, showSendTokens, setShowSendTokens }) => {

    const { user, walletConnector } = useDynamicContext();
    const [balance, setBalance] = useState();
    const [USD, setUSD] = useState();
    const [count, setCount] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [referralCode, setReferralCode] = useState(null);
    const goal = count >= 5 && count < 15 ? 15 : count >= 15 ? 40 : 5;
    const userWallets = [];
    
    const shareContent = async () => {
        // If the user is on a mobile device, use the native share API
        // Check the device type
        // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        // if (isMobile) {
    //     if (navigator.share) {
    //       try {
    //         await navigator.share({
    //           title: 'Get free VEX tokens',
    //           text: 'Sign up to the app now and earn free VEX tokens. Cash them out using our DeFi Crypto ATM! #velorex',
    //           url: `https://velorex.net/?ref=${referralCode}`,
    //         });
    //       } catch (error) {
    //         console.error('Error sharing:', error);
    //         // Handle error if sharing fails
    //       }
    //     }
        // } else {
            const tweetOptions = [
                `💰 Sign up now & earn free crypto!\n💳 Buy $VEX before anyone else!\n\n✨ Create a free account in seconds:\n🌐 https://velorex.net/?ref=${referralCode}\n\n#Crypto #Velorex #CryptoRewards #Bitcoin #Binance\n\nhttps://youtube.com/shorts/LmDaJFfzHGc?si=TAviNUTie-qoJoln`,
                `🟢 Activate your $VEX wallet to earn free crypto\n\n💳 Top up $5 of VEX to become eligible for the airdrop\n\n✨ Create a free account in seconds:\n🌐 https://velorex.net/?ref=${referralCode}\n\n#Crypto #Velorex #CryptoRewards #airdrop #airdrops\n\nhttps://www.youtube.com/shorts/tKP5nkWi-80`,
              ];
        //const selectedTweet = tweetOptions[Math.floor(Math.random() * tweetOptions.length)];
        const selectedTweet = tweetOptions[1];
        const tweetContent = encodeURIComponent(selectedTweet);
        window.open(`https://twitter.com/intent/tweet?text=${tweetContent}`);
    // }
    };
      
    const handleCopyCode = async () => {
        const textCopy = await getReferralCode();
        
        try {
            if (typeof ClipboardItem !== 'undefined' && navigator.clipboard && navigator.clipboard.write) {
                // Chrome & Safari supporting ClipboardItem
                const clipboardItem = new ClipboardItem({ 'text/plain': new Blob([textCopy], { type: 'text/plain' }) });
                await navigator.clipboard.write([clipboardItem]);
                toast.success("Copied to clipboard!", {
                    position: 'bottom-center',
                });
            } else if (navigator.clipboard && navigator.clipboard.writeText) {
                // Fallback for Firefox and other browsers supporting writeText
                await navigator.clipboard.writeText(textCopy);
                toast.success("Copied to clipboard!", {
                    position: 'bottom-center',
                });
            } else {
                // Browser doesn't support Clipboard API at all
                throw new Error('Clipboard API not supported in this browser');
            }
        } catch (error) {
            console.error('Clipboard copy failed: ', error);
            // Handle errors (optional)
            toast.error("Failed to copy to clipboard!", {
                position: 'bottom-center',
            });
        }
    };    

    // Get the users referral code from the backend
    const getReferralCode = async () => {
        const userId = user?.userId;
        try {
            const response = await fetch(`${urlEnv}/api/users/referral`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId })
            });
            const referralCode = await response.json();
            setReferralCode(referralCode.referral)
            // Attach the referral code to the website URL and return it
            const url = `https://velorex.net/?ref=${referralCode.referral}`;
            return url;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    // Get the number of referrals the user has
    const getReferralCount = async () => {
        const userId = user?.userId;
        try {
            const response = await fetch(`${urlEnv}/api/codes/usage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId })
            });
            const referralCount = await response.json();
            return referralCount.usageCount;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    // Get the price of VEX using moralis
    const getVexPrice = async () => {
        const response = await fetch(`${urlEnv}/api/vexPrice`);
        const { vexPrice } = await response.json();
        return vexPrice;
    }

    // Get the token balance from the backend
    const getTokenBalance = async () => {
        const response = await fetch(`${urlEnv}/api/tokenBalances`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ wallets: userWallets })
        });
        const { totalBalance } = await response.json();

        const price = await getVexPrice();

        setBalance(totalBalance);
        setUSD(totalBalance * price)
    }

    // Throttle function definition
    function throttle(func, limit) {
        let inThrottle;
        return function (...args) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
        };
    }

    useEffect(() => {
        const throttledEffect = throttle(async () => {
            setLoaded(false);
            userWallets.length = 0;
            if (!user) return;
     
            // Collect all the users wallets
            user.verifiedCredentials.forEach((credential) => {
                if (credential.address) {
                userWallets.push(credential.address);
                }
            });
      
            // Get the token balance
            getTokenBalance().then(() => {
                setLoaded(true);
            });
      
            getReferralCode();
      
            // Get the number of referrals the user has made
            getReferralCount().then((referralCount) => {
                setCount(referralCount);
            });
            }, 1000); // Adjust the throttling interval as needed (e.g., 1000ms = 1 second)
      
            throttledEffect(); // Initial immediate execution
      
            // Clean up the throttled effect
            return () => {
            clearTimeout(throttledEffect);
            };
      }, [user]);

    useEffect(() => {
        walletConnector?.switchNetwork({ networkChainId: 56 });
    }
    , [walletConnector])

    const toggleBuyTokens = () => {
        setShowBuyTokens((prev) => !prev);
    };

    const toggleSendTokens = () => {
        setShowSendTokens((prev) => !prev);
    }
    
  return (
    <>    
    {showSendTokens && <SendTokens vexBalance={balance} toggleVisibility={toggleSendTokens} />}
    {showBuyTokens && <BuyTokens vexBalance={balance} toggleVisibility={toggleBuyTokens} />}
    {!showBuyTokens && !showSendTokens && 
        <div>
            <ToastContainer />
            {user ? (
                <>
                    <div className="topbar" >
                        <div className="topbar-container">
                            <div className="horizontal-flex token-box">
                                <div className='modal-text-box'>
                                    <div className="horizontal-flex">
                                        <button onClick={() => toggleBuyTokens()} className='btn-secondary3 modal-text topbtm'><img className='token-logo mrgright' src={velorex} alt="" />Trade</button>
                                        <button onClick={() => toggleSendTokens()} className='btn-secondary3 modal-text topbtm'><img className='token-logo mrgright' src={send} alt="" />Send</button>
                                    </div>
                                    {loaded ? (
                                        <div className="stackright">
                                             <p className='modal-text-right'>${num(USD?.toFixed(2))}</p>
                                             <p className='modal-text-right'><span className='faded smaller'>{num(balance?.toFixed())} VEX</span></p>
                                        </div>
                                    ) : (
                                        <div className='loading-skeleton'/>
                                    )}
                                </div>
                            </div>
                            <div className="horizontal-flex token-box">
                                <div className='modal-text-box'>
                                    <p className='modal-text'>
                                        <img className='token-logo mrgright' src={Gift} alt="" />
                                        Earn free tokens
                                    </p>
                                    <p className='modal-text-right'>
                                        <div onClick={shareContent} className='btn-secondary3 nomrg'>
                                            <span className='faded'>{!count ? 0 : count}/{goal} invites</span>
                                        </div>
                                        <button onClick={() => handleCopyCode()} className='nobuttonstyling'>
                                            <img className='token-logo copy-logo' style={{cursor: "pointer"}} src={Copy} alt="" />
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="horizontal-flex space-between">
                                <p className='prize lighter smaller'>
                                    Next reward: {" "} 
                                    <span className='bold'>
                                        {count >= 5 && count < 15 ? "2,500 VEX" : count >= 15 ? "5,000 VEX" : "1,000 VEX"}
                                    </span>
                                </p>
                                <p className='prize lighter smaller mrgright'>
                                    Earned: {" "} 
                                    <span className='bold'>
                                        {count >= 5 && count < 15 ? "1,000 VEX" : count >= 15 && count < 40 ? "3,500 VEX" : count >= 40 ? "8,500 VEX" : "0 VEX"}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                null
            )}
            <DynamicEmbeddedWidget background="default">
            </DynamicEmbeddedWidget>
            <p style={{fontSize: "11px", opacity: "0.5", maxWidth: "300px", marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>* Limited offer subject to availability. By participating you agree to the terms <button className='nobuttonstyling underline' onClick={() => window.location.href="/legal"}>here</button></p>
        </div>
    }
    </>
  )
}

export default Widget