import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'
import pancakeABI from '../Components/ABI/pancakeABI.json'
import { parseEther } from 'viem'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import React, {useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BuyVEX = ({ baseAmount }) => {

    const { primaryWallet } = useDynamicContext();
    const primaryWalletAddress = primaryWallet?.address
    const [nativeBalance, setNativeBalance] = useState(null);

    const amountOutMin = "0";
    const routerAddress = "0x10ed43c718714eb63d5aa57b78b54704e256024e";
    const swapFunction = 'swapExactETHForTokensSupportingFeeOnTransferTokens';
    const tokenA = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
    const tokenB = "0xc029a12e4a002c6858878fd9d3cc74e227cc2dda";
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

    useEffect(() => {
        const fetchBalance = async () => {
          if (primaryWallet) {
            const value = await primaryWallet.connector.getBalance();
            setNativeBalance(value);
          }
        };
        fetchBalance();
      }, [primaryWallet]);

    const { 
            config,
            error: prepareError,
            isError: isPrepareError
        } = usePrepareContractWrite({
                value: baseAmount > 0.001 ? parseEther(baseAmount.toString()) : null,
                address: routerAddress,
                abi: pancakeABI,
                functionName: swapFunction,
                args: [
                    amountOutMin,
                    [tokenA, tokenB],
                    primaryWalletAddress,
                    deadline,
                ]
            })

    const { data, error, isError, write } = useContractWrite(config)

    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
      })

      useEffect(() => {
        if (isPrepareError && Number(baseAmount) > 0.000001) {
            toast.error(prepareError.message.split('.')[0], {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true
                });
        }
      }, [isPrepareError])

      useEffect(() => {
        if (isError && baseAmount > 0) {
            toast.error(error.message.split('.')[0], {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true
                });
        }
      }, [isError])

      useEffect(() => {
        if (isSuccess) {
            toast.success('Success', {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true
                });
        }
      }, [isSuccess])

return (
    <div>
        { nativeBalance > 0.0001 ? (
        <>
        {isSuccess ? (
            <button disabled className='mainbutton leftright'>Success</button>
          ) : (
            <button className='mainbutton leftright' disabled={!write || baseAmount === 0} onClick={() => write()}>
                {isLoading ? 'Swapping' : 'Buy'}
            </button>
        )} 
        </>
        ) : (
            <button className='mainbutton leftright' disabled>Insufficient BNB</button>
        )}
    </div>
)

}

export default BuyVEX