import React, { useState } from 'react'
import './ATM.css'
import ReactMapGl from 'react-map-gl'
import { Marker } from 'react-map-gl'
import "mapbox-gl/dist/mapbox-gl.css"
import {
    Ball
} from '../../Assets/Image'

const token = process.env.REACT_APP_TOKEN

const ATM = () => {

const [viewPort] = useState({
    latitude: 41.063149,
    longitude: 28.806826,
    zoom: 16,
    bearing: 10,
    pitch: 70
  })

  const [settings] = useState({
    scrollZoom: true,
    boxZoom: true,
    dragRotate: true,
    dragPan: true,
    keyboard: true,
    doubleClickZoom: true,
    touchZoomRotate: true,
    touchPitch: true,
    minPitch: 0,
    maxPitch: 85
  });

  let size = 40;

  return (
    <div>
        <div className="map-section">
                <div className="atmheading">
                    <img src={Ball} className='heading-ball' alt="" />
                    <h1>Find an ATM</h1>
                </div>
            <div className="map-container">
                <ReactMapGl
                    initialViewState={viewPort}
                    {...settings}
                    mapboxAccessToken={token}
                    width="100%"
                    height="100%"
                    mapStyle="mapbox://styles/toddvelorex/clpapo2bb005j01poeu0j11m6"
                >
                    <Marker
                        style={{ transform: `translate(${-size / 2}px,${-size}px)` }} 
                        latitude={41.063149}
                        longitude={28.806826}
                    />
                </ReactMapGl>
            </div>
        </div>
        <div className="atm-section">
            <div className="chat-container">
            <div className="heading">
                    <img src={Ball} className='heading-ball' alt="" />
                    <h1>None in your area?</h1>
                </div>
                <p className='content-text'>If you can't find an ATM in your area, please press the button below to request us to install one and we'll get it done as soon as we can - or you can buy your very own Velorex crypto ATM and earn passive income while providing a service to your local community.<br/><br/>Arrange a call with the team if you want more information.</p>
                <div className="spacer-small"/>
                <button className='btn-primary' onClick={() => window.location.href = "/contact?message=true"}>Request an ATM</button>
                <button className='btn-secondary' onClick={() => window.open(`https://crypto-atm.shop`, "_blank")}>Shop Now</button>
            </div>
            <div className="spacer"></div>
        </div>
    </div>
  )
}

export default ATM