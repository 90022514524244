import React, { useState } from 'react'
import {
  Logo,
  HamburgerOpen,
  HamburgerClose
} from '../../Assets/Image'
import './Navbar.css'
import { FaMapMarkerAlt } from "react-icons/fa";
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { FaUser } from "react-icons/fa";
import { FaRegTimesCircle } from 'react-icons/fa';
import Widget from '../Widget/Widget';

const Navbar = () => {

  const { user } = useDynamicContext();
  const [isVisible, setIsVisible] = useState(false);
  const [showBuyTokens, setShowBuyTokens] = useState(false);
  const [showSendTokens, setShowSendTokens] = useState(false);

  const toggleVisibility = () => { 
    scrollToTop(); 
    setIsVisible((prevVisible) => !prevVisible);
    setShowBuyTokens(false);
    setShowSendTokens(false);
};

  const handleCreateAccount = () => {
    toggleVisibility()
  }

  const [navOpen, setNavOpen] = useState(false);
  const navigateTo = (location) => {
    window.location.href = location
  }
  const toggleNav = () => {
    setNavOpen(!navOpen)
  }
  const closeNavIfOpen = () => {
    if (navOpen) {
      setNavOpen(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

  return (
    <>
        <div className="widget-container" style={{opacity: isVisible ? 'flex' : 'none', transform: isVisible ? 'translate(-50%, -50%)' : 'translate(-50%, -300%)'}}>
            <button className='nobuttonstyling' onClick={() => toggleVisibility()}><FaRegTimesCircle size={30} opacity={1} style={{marginBottom: "6px", color: "white", cursor: "pointer"}} /></button>
            <Widget showSendTokens={showSendTokens} setShowSendTokens={setShowSendTokens} showBuyTokens={showBuyTokens} setShowBuyTokens={setShowBuyTokens} id="widget"/>
        </div>
      <div onClick={() => closeNavIfOpen()}>
        <div className={`nav-menu ${navOpen ? 'show' : ''}`}>
                    <div className="nav-link-block fade" onClick={() => navigateTo("/faq")}>
                      <p className='text'>FAQ</p>
                    </div>
                    <div className="nav-link-block fade" onClick={() => navigateTo("/contact")}>
                      <p className='text'>Contact</p>
                    </div>
                    <div className="nav-link-block fade" onClick={() => navigateTo("/find")}>
                      <div className="horizontal-flex">
                        <FaMapMarkerAlt/> 
                        <p className='text'>ATM</p>
                      </div>
                    </div>
                    {!user ? (
                      <div className="horizontal-flex">
                        <div className="nav-link-block" onClick={() => handleCreateAccount()}>
                          <button className='btn-primary nomarginright'>Sign up</button>
                        </div>
                        <div className="nav-link-block" onClick={() => handleCreateAccount()}>
                          <button className='btn-secondary'>Log in</button>
                        </div>
                    </div>
                    ) : (
                      <>
                      <div className="link-block fade"  onClick={() => navigateTo("/welcome")}>
                        <p className='text'>Welcome</p>
                      </div>
                    <div className="nav-link-block" onClick={() => handleCreateAccount()}>
                      <button className='btn-primary nomarginright'>
                        <div className="horizontal-flex">
                          <FaUser style={{ marginRight: "6px"}} />
                          My account
                        </div>
                      </button>  
                    </div>
                      </>
                    )}
        </div>
        <section className="nav-container">
          <div className="nav">
            <div className="nav-image" onClick={() => navigateTo("/")}>
              <img src={Logo} alt="Velorex logo" />
            </div>
            <div className="navbar">
              <div className="link-block fade"  onClick={() => navigateTo("/faq")}>
                <p className='text'>FAQ</p>
              </div>
              <div className="link-block fade"  onClick={() => navigateTo("/contact")}>
                <p className='text'>Contact</p>
              </div>
              <div className="link-block fade" onClick={() => navigateTo("/find")}>
                <div className="horizontal-flex">
                  <FaMapMarkerAlt /> 
                  <p className='text'>ATM</p>
                </div>
              </div>
              
              {!user ? (
              <div className="link-block2">
                <button className='btn-primary' onClick={() => handleCreateAccount(true)}>Sign up</button>
                <button className='btn-secondary' onClick={() => handleCreateAccount(true)}>Log in</button>
              </div>
              ) : (
                <>
              <div className="link-block fade"  onClick={() => navigateTo("/welcome")}>
                      <p className='text' style={{marginRight: "10px"}}>Welcome</p>
                    </div>
              <button className='btn-primary2' onClick={() =>  handleCreateAccount()
                  }><FaUser color='#171717'/></button>
              </>
              )}
              </div>
            
            <div className="navbar-mobile">
              <div className="mobile-nav">
                <div className="link-block2">
                  <button className='btn-primary2' onClick={() =>  handleCreateAccount()
                  }><FaUser color='#171717'/></button>
                </div>
                <img
                  src={navOpen ? HamburgerClose : HamburgerOpen}
                  className='hamburger'
                  alt="Navigation menu"
                  onClick={toggleNav}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Navbar