import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'
import React, {useEffect} from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import velorexABI from '../Components/ABI/velorexABI.json'

const SendVEX = ({ amount, sendTo }) => {
    const token = "0xc029a12e4a002c6858878fd9d3cc74e227cc2dda";

    const realWalletAddress = (address) => {
        if (address.length === 42 && address.startsWith('0x')) {
            return true;
        } else {
            return false;
        }
    }

    // Send tokens
    const { 
            config,
            error: prepareError,
            isError: isPrepareError
        } = usePrepareContractWrite({
                address: token,
                abi: velorexABI,
                functionName: 'transfer',
                args: [
                    sendTo,
                    Math.floor(Number(amount) *10**9)
                ]
            })

    const { data, error, isError, write } = useContractWrite(config)

    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
      })

      useEffect(() => {
        if (isError && amount > 0) {
            toast.error("Sorry, please try again", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true
                });
        }
      }, [isError])
            
return (
    <div>
        {!isSuccess && (
            <button className='mainbutton leftright' disabled={!write || amount === 0 || !realWalletAddress(sendTo)} onClick={() => write()}>
            {isLoading ? 'Sending' : 'Send'}
        </button>
        )} 
    </div>
)

}

export default SendVEX