import React from 'react'

import Navbar from '../Components/Navbar/Navbar';
import HeroSection from '../Components/Hero/Hero';
import Products from '../Components/Products/Products';
import WhatIsVelorex from '../Components/GetStarted/GetStarted';
import ChoiceControl from '../Components/ChoiceControl/ChoiceControl';
import StartNow from '../Components/StartNow/StartNow';
import Socials from '../Components/Socials/Socials';
import News from '../Components/News/News';
import Footer from '../Components/Footer/Footer';

const Home = () => {
  return (
    <div>
        <Navbar />
        <HeroSection />
        <Products />
        <WhatIsVelorex />
        <ChoiceControl />
        <StartNow />
        <Socials />
        <News />
        <Footer />
    </div>
  )
}

export default Home